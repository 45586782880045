<template>
  <svg
    id="a9e06111-6f74-41cd-a7ac-d054423684ef"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="975.94355"
    height="564.45666"
    viewBox="0 0 975.94355 564.45666"
  >
    <ellipse cx="232" cy="552.45666" rx="232" ry="12" fill="#e6e6e6" />
    <path
      d="M451.92775,473.87308l-13.33833-6.66917,11.11528-30.01125,2.92936-15.135a11.44372,11.44372,0,0,1,18.68269-6.51417l0,0a11.44373,11.44373,0,0,1,1.48857,15.83756l-10.87383,13.5923Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#a0616a"
    />
    <path
      d="M456.3782,470.53833l-2.46,7.7-11.04,34.59a18.49674,18.49674,0,0,1-33.49,3.89l-35.79-61.34a19.28682,19.28682,0,0,1-1.46-3,18.88595,18.88595,0,0,1,34.6-15.05l21.85,46.55,10-22.23Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M451.22618,693.97471l-24.45362,2.22306-13.3384-96.70294c-43.96741,7.39683-85.44668,9.76641-121.32445.81844a22.65024,22.65024,0,0,1-16.10039-29.08991c4.29388-12.81525,11.85891-26.54821,20.71447-40.64326l58.911,21.119,2.22306,8.89222,62.836-7.95392a23.063,23.063,0,0,1,25.94222,21.994Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#2f2e41"
    />
    <path
      d="M477.85716,712.7125h0a8.74581,8.74581,0,0,0-3.91362-8.57729l-25.10183-12.31863-18.92529,2.38909-4.46965,16.02324a8.52724,8.52724,0,0,0,7.45967,9.86672l35.31448.07719A8.74579,8.74579,0,0,0,477.85716,712.7125Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#2f2e41"
    />
    <path
      d="M457.4034,681.61048l-24.45361,2.223L419.61138,587.1306c-43.96741,7.39683-85.44668,9.7664-121.32444.81843a22.65023,22.65023,0,0,1-16.1004-29.0899c4.29389-12.81525,11.85891-26.54822,20.71447-40.64327l58.911,21.119,2.22305,8.89222,62.836-7.95393a23.063,23.063,0,0,1,25.94222,21.994Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#2f2e41"
    />
    <path
      d="M484.03438,700.34827h0a8.7458,8.7458,0,0,0-3.91362-8.5773l-25.10182-12.31862-18.9253,2.38908L431.624,697.86466a8.52724,8.52724,0,0,0,7.45966,9.86673l35.31448.07719A8.7458,8.7458,0,0,0,484.03438,700.34827Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#2f2e41"
    />
    <circle cx="300.91406" cy="219.28389" r="30.01126" fill="#a0616a" />
    <polygon
      points="299.803 280.418 265.345 275.972 278.683 234.845 312.029 234.845 299.803 280.418"
      fill="#a0616a"
    />
    <path
      d="M421.20822,489.65832a47.07576,47.07576,0,0,1-7.5,13.42,64.01093,64.01093,0,0,1-16.3,14.22c-1.08.69995-2.2,1.37-3.36,2.03l-6.62,5.64-23.39,19.92-64.47-24.45c14.22-31.6,31.96-56.58,52.29-76.7a36.67277,36.67277,0,0,1,39.19-14.92l24.12,6.03C423.7482,455.09833,426.9382,473.71832,421.20822,489.65832Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M431.7918,444.13255a2.327,2.327,0,0,0-.04766,3.27379c.74226.321,15.44949,17.08113,16.35208,15.92745a2.31634,2.31634,0,0,0,1.66019-.67619l44.87215-44.67778a2.31329,2.31329,0,0,0,.67145-1.88375c1.21093-1.13493-18.097-12.57589-18.68711-13.19845a2.30751,2.30751,0,0,0-2.88849.28431Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <path
      d="M490.78154,415.61943l-1.826-1.16425a1.74942,1.74942,0,0,1-2.50411,2.28425l-11.8164-8.12372a1.75075,1.75075,0,0,1,.45972-3.09141c-.46774-.15878-1.22392-1.109-1.73141-.51085l-39.75143,39.06477c-1.41616.68933,14.4437,14.43294,14.45228,14.97292,1.18355,1.31947,41.95867-42.41955,42.82573-42.44965A.6343.6343,0,0,0,490.78154,415.61943Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#fff"
    />
    <polygon
      points="332.874 267.681 345.068 279.178 374.074 250.521 359.353 240.94 332.874 267.681"
      fill="#19e06f"
    />
    <path
      d="M484.97483,425.36522a.43264.43264,0,0,0,.597.00014c.13338-.37117,3.22968-2.62594,2.53635-3.13343a.43265.43265,0,0,0-.597-.00015c-.13339.37118-3.22969,2.62595-2.53635,3.13344Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M481.299,429.63833c.13336-.37121,3.22988-2.62608,2.53621-3.13372-.51623-.68669-2.75163,2.39587-3.13325,2.53672C480.30889,429.42346,480.918,430.02973,481.299,429.63833Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M448.03249,445.32143c.054,3.69591-5.75169,3.696-5.69721-.00026C442.28126,441.62551,448.087,441.62545,448.03249,445.32143Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#19e06f"
    />
    <path
      d="M413.70822,503.07831a64.01093,64.01093,0,0,1-16.3,14.22c-1.08.69995-2.2,1.37-3.36,2.03l-6.62,5.64c-13.14-17.27-12.4-69.74-12.4-69.74l20,2Z"
      transform="translate(-112.02823 -167.77167)"
      opacity="0.2"
    />
    <path
      d="M452.95729,489.31614,439.619,482.647l11.11528-30.01126,2.92936-15.135a11.44373,11.44373,0,0,1,18.6827-6.51416l0,0a11.44373,11.44373,0,0,1,1.48857,15.83756L462.961,460.41641Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#a0616a"
    />
    <path
      d="M439.619,477.08933l17.78445,8.89222-13.49654,42.28918a18.50042,18.50042,0,0,1-13.33949,12.37243h0a18.50037,18.50037,0,0,1-20.149-8.47891l-35.7889-61.34385a18.88462,18.88462,0,0,1,6.08917-25.67014l0,0a18.88461,18.88461,0,0,1,27.05223,7.62372l21.84432,46.54593Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M424.23841,375.3662a29.43049,29.43049,0,0,0,8.17366,4.82352c3.013,1.01141,6.51088,1.03292,9.2004-.66052,3.17155-1.99693,4.619-6.053,4.294-9.78672s-2.15688-7.1788-4.34476-10.22177a43.756,43.756,0,0,0-70.76844-.3912c-2.80768,3.8153-5.033,8.24564-5.27641,12.97643-.429,8.33748,5.276,16.12046,4.66607,24.44667-.51591,7.04225-5.57052,13.07491-11.55712,16.81926s-12.90483,5.64281-19.59836,7.8914-13.46237,5.02333-18.41646,10.05488-7.68658,12.83937-4.96726,19.35587c2.2345,5.35466,7.54428,8.76013,12.86847,11.06627a62.75243,62.75243,0,0,0,45.00794,1.68936c5.41757-1.87817,11.33342-5.636,11.41577-11.36929.03574-2.48835-1.08417-4.82463-1.7566-7.22067s-.79835-5.25384.8883-7.0837c2.09182-2.26944,5.95751-1.74673,8.45756.0632s4.12541,4.55523,6.188,6.85124c3.91639,4.35955,9.85344,7.14454,15.656,6.32336s11.04713-5.76415,11.28713-11.6196c.41229-10.05865-12.18965-16.35624-13.55972-26.32967-.91505-6.66107,3.42169-12.755,7.17952-18.33044s7.25283-12.56094,4.62243-18.74868"
      transform="translate(-112.02823 -167.77167)"
      fill="#2f2e41"
    />
    <path
      d="M262.52823,610.72833a25.01214,25.01214,0,0,1-23.03418-34.73193l3.68359,1.55859a21.01327,21.01327,0,0,0,27.14014,27.68164l1.48437,3.71485A24.88134,24.88134,0,0,1,262.52823,610.72833Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <path
      d="M236.52433,584.56692h0a10.84578,10.84578,0,0,1-10.49609-10.84014V394.22833h0a9.28425,9.28425,0,0,1,14.02081,1.02156c51.11815,69.12232,53.36978,131.10647,4.93647,185.68685A10.84847,10.84847,0,0,1,236.52433,584.56692Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#19e06f"
    />
    <path
      d="M224.52823,573.72833h0v-179h0a5.11806,5.11806,0,0,1,4.46467,2.62645C252.25649,439.111,269.51438,472.19652,269.043,502.1809c-.421,26.77677-19.23247,39.13967-35.64152,66.53819A10.34,10.34,0,0,1,224.52823,573.72833Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <polygon
      points="189 442.457 158 442.457 158 434.457 183 427.457 189 442.457"
      fill="#3f3d56"
    />
    <path
      d="M178,434.45666h82a0,0,0,0,1,0,0v0a17,17,0,0,1-17,17H195a17,17,0,0,1-17-17v0A0,0,0,0,1,178,434.45666Z"
      fill="#3f3d56"
    />
    <rect x="217" y="449.45666" width="11" height="9" fill="#3f3d56" />
    <rect x="213" y="456.45666" width="20" height="27" fill="#3f3d56" />
    <rect x="214.5" y="497.95666" width="17" height="36" fill="#3f3d56" />
    <polygon
      points="240.5 501.957 205.5 501.957 210.5 479.957 240.5 479.957 240.5 501.957"
      fill="#3f3d56"
    />
    <path
      d="M321.52823,667.72833l-71,33-1,5h-10l.47812-7.6499a14.61353,14.61353,0,0,1,7.98693-12.1276l75.535-38.2225Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <path
      d="M348.52823,667.72833l75,33,1,5h10l-.47812-7.6499a14.61356,14.61356,0,0,0-7.98694-12.1276l-73.53494-38.2225Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <circle cx="131.5" cy="547.95666" r="11" fill="#3f3d56" />
    <circle cx="258.5" cy="547.95666" r="11" fill="#3f3d56" />
    <circle cx="317.5" cy="547.95666" r="11" fill="#3f3d56" />
    <path
      d="M374.52823,708.72833l-8-1-2-7.99951-27-33.00049,12-12L370.88588,687.192a22.13072,22.13072,0,0,1,3.64235,12.16342Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#3f3d56"
    />
    <path
      d="M407.37075,604.72833h-132.685a13.15747,13.15747,0,0,1-13.15748-13.15748v-7.13221a20.52273,20.52273,0,0,1,7.454-15.814c22.39826-18.51115,40.528-34.25142,65.5461-32.99706,21.61532,1.08375,48.939,28.28562,72.50076,36.67767a20.41991,20.41991,0,0,1,13.49915,19.26562v0A13.15748,13.15748,0,0,1,407.37075,604.72833Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#19e06f"
    />
    <path
      d="M970.38237,500.96233H596.92081a6.92409,6.92409,0,0,1-6.91595-6.916V174.68761a6.92409,6.92409,0,0,1,6.91595-6.91594H970.38237a6.92408,6.92408,0,0,1,6.91594,6.91594V494.04638A6.92409,6.92409,0,0,1,970.38237,500.96233ZM596.92081,170.53805a4.1541,4.1541,0,0,0-4.14957,4.14956V494.04638a4.1541,4.1541,0,0,0,4.14957,4.14957H970.38237a4.15411,4.15411,0,0,0,4.14957-4.14957V174.68761a4.15411,4.15411,0,0,0-4.14957-4.14956Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <circle cx="542.92091" cy="276.40018" r="28.17189" fill="#e6e6e6" />
    <path
      d="M720.01279,425.39058a4.69531,4.69531,0,1,0,0,9.39063H941.36338a4.69531,4.69531,0,0,0,0-9.39063Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M720.01279,453.56248a4.69532,4.69532,0,1,0,0,9.39063h95.24783a4.69532,4.69532,0,0,0,0-9.39063Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#e6e6e6"
    />
    <path
      d="M1059.11228,389.57385H685.65072a6.92408,6.92408,0,0,1-6.91594-6.91594V218.76354a6.92409,6.92409,0,0,1,6.91594-6.916h373.46156a6.92409,6.92409,0,0,1,6.91595,6.916V382.65791A6.92409,6.92409,0,0,1,1059.11228,389.57385Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#19e06f"
    />
    <path
      d="M761.70621,268.97409a4.69532,4.69532,0,0,0,0,9.39064H983.0568a4.69532,4.69532,0,0,0,0-9.39064Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#fff"
    />
    <path
      d="M761.70621,296.18036a4.69532,4.69532,0,0,0,0,9.39064H983.0568a4.69532,4.69532,0,0,0,0-9.39064Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#fff"
    />
    <path
      d="M761.70621,323.05672a4.69531,4.69531,0,1,0,0,9.39063H856.954a4.69531,4.69531,0,0,0,0-9.39063Z"
      transform="translate(-112.02823 -167.77167)"
      fill="#fff"
    />
    <circle cx="947.77165" cy="211.29131" r="28.17189" fill="#3f3d56" />
    <polygon
      points="945.423 223.748 936.482 212.251 941.682 208.207 945.915 213.65 960.217 198.553 965 203.084 945.423 223.748"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "RegisterSvg",
};
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
}
</style>
