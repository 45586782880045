<template>
  <v-container class="child-view">
    <v-row justify="center" no-gutters class="fill-height" align="center">
      <v-col sm="8" cols="12">
        <v-card class="elevation-3">
          <v-row align="center" justify="space-around">
            <v-col
              v-if="$vuetify.breakpoint.lgAndUp && (isLogin || isResetPassword)"
              lg="5"
              cols="12"
            >
              <div class="d-flex justify-center align-center">
                <login-svg v-if="isLogin" />
                <register-svg v-else-if="isRegister" />
                <reset-password-svg v-else-if="isResetPassword" />
              </div>
            </v-col>
            <v-col lg="5" cols="12">
              <v-row>
                <v-col cols="12">
                  <div class="text-center mb-8">
                    <blue-logo-with-name
                      style="max-width: 50%; cursor: pointer"
                      @click="redirectLogin"
                    />
                  </div>
                  <router-view></router-view>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginSvg from "@/components/svg/Login";
import RegisterSvg from "@/components/svg/Register";
import BlueLogoWithName from "@/components/svg/BlueLogoWithName";
import ResetPasswordSvg from "@/components/svg/ResetPassword";
export default {
  name: "LayoutAuth",
  components: {
    ResetPasswordSvg,
    BlueLogoWithName,
    RegisterSvg,
    LoginSvg,
  },
  data: () => ({
    transitionName: "slide-left",
  }),
  computed: {
    isLogin() {
      return this.$route.name.indexOf("login") > 0;
    },
    isRegister() {
      return this.$route.name.indexOf("register") > 0;
    },
    isResetPassword() {
      return this.$route.name.indexOf("resetPassword") > 0;
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
  methods: {
    redirectLogin() {
      this.$router.push({ name: "auth.login" });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.child-view {
  transition: all 0.75s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
