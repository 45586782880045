<template>
  <svg
    id="fafd2220-3b39-4538-bdd8-5627e33a2e32"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1097.1"
    height="811.81"
    viewBox="0 0 1097.1 811.81"
  >
    <defs>
      <linearGradient
        id="1c2fe1a5-71ac-4c34-8a58-b17380e220b9"
        x1="593.13"
        y1="855.9"
        x2="593.13"
        y2="67.88"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="gray" stop-opacity="0.25" />
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
        <stop offset="1" stop-color="gray" stop-opacity="0.1" />
      </linearGradient>
    </defs>
    <title>forgot password</title>
    <g opacity="0.1">
      <path
        d="M929.91,619.25S851.59,609.08,910,735.84c0,0,53.23-22.81,55.35-74.26a40.94,40.94,0,0,0-34.33-42.17Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M931.06,619.06s17.73,70.68-21,116.79"
        transform="translate(-51.45 -44.1)"
        fill="none"
        stroke="#535461"
        stroke-miterlimit="10"
      />
      <path
        d="M909,742.38s28.2-69.5,80.86-95.28A113.65,113.65,0,0,0,1041.47,597a191.13,191.13,0,0,0,12.93-32.47"
        transform="translate(-51.45 -44.1)"
        fill="none"
        stroke="#535461"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <path
        d="M1087.49,548.1c-5.49,6.9-33.9,17-33.9,17s3.42-30,8.91-36.85a16,16,0,1,1,25,19.88Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1077.74,602.75c-8.36,2.81-37.68-4.18-37.68-4.18s19.15-23.28,27.51-26.09a16,16,0,1,1,10.17,30.27Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1024.88,667.36c-8.63-1.79-30.42-22.63-30.42-22.63s28.28-10.43,36.92-8.64a16,16,0,0,1-6.5,31.26Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M973.88,707.24c-8.81-.31-33.81-17.16-33.81-17.16S966.19,675,975,675.32a16,16,0,0,1-1.12,31.91Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1012.76,579c1.13,8.75,20.22,32.07,20.22,32.07s12.57-27.4,11.45-36.15a16,16,0,1,0-31.67,4.08Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M953.7,626.44c3.69,8,28.89,24.55,28.89,24.55s3.8-29.91.11-37.91a16,16,0,1,0-29,13.37Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M902.62,680.38c2.28,8.52,24.33,29.08,24.33,29.08s8.8-28.83,6.51-37.35a16,16,0,1,0-30.84,8.27Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1087.49,548.1c-5.49,6.9-33.9,17-33.9,17s3.42-30,8.91-36.85a16,16,0,1,1,25,19.88Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M1077.74,602.75c-8.36,2.81-37.68-4.18-37.68-4.18s19.15-23.28,27.51-26.09a16,16,0,1,1,10.17,30.27Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M1024.88,667.36c-8.63-1.79-30.42-22.63-30.42-22.63s28.28-10.43,36.92-8.64a16,16,0,0,1-6.5,31.26Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M973.88,707.24c-8.81-.31-33.81-17.16-33.81-17.16S966.19,675,975,675.32a16,16,0,0,1-1.12,31.91Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M1012.76,579c1.13,8.75,20.22,32.07,20.22,32.07s12.57-27.4,11.45-36.15a16,16,0,1,0-31.67,4.08Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M953.7,626.44c3.69,8,28.89,24.55,28.89,24.55s3.8-29.91.11-37.91a16,16,0,1,0-29,13.37Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M902.62,680.38c2.28,8.52,24.33,29.08,24.33,29.08s8.8-28.83,6.51-37.35a16,16,0,1,0-30.84,8.27Z"
        transform="translate(-51.45 -44.1)"
        opacity="0.25"
      />
      <path
        d="M946.56,774s28.2-69.5,80.86-95.28A113.65,113.65,0,0,0,1079,628.61a191.13,191.13,0,0,0,12.93-32.47"
        transform="translate(-51.45 -44.1)"
        fill="none"
        stroke="#535461"
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <path
        d="M1125,579.7c-5.49,6.9-33.9,17-33.9,17s3.42-30,8.91-36.85a16,16,0,0,1,25,19.88Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1115.27,634.35c-8.36,2.81-37.68-4.18-37.68-4.18s19.15-23.28,27.51-26.09a16,16,0,0,1,10.17,30.27Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1062.41,699c-8.63-1.79-30.42-22.63-30.42-22.63s28.28-10.43,36.92-8.64a16,16,0,0,1-6.5,31.26Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1011.4,738.83c-8.81-.31-33.81-17.16-33.81-17.16s26.12-15.06,34.93-14.75a16,16,0,0,1-1.12,31.91Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M1050.28,610.64c1.13,8.75,20.22,32.07,20.22,32.07s12.57-27.4,11.45-36.15a16,16,0,1,0-31.67,4.08Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M991.23,658c3.69,8,28.89,24.55,28.89,24.55s3.8-29.91.11-37.91a16,16,0,0,0-29,13.37Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
      <path
        d="M940.15,712c2.28,8.52,24.33,29.08,24.33,29.08s8.8-28.83,6.51-37.35A16,16,0,0,0,940.15,712Z"
        transform="translate(-51.45 -44.1)"
        fill="#19e06f"
      />
    </g>
    <path
      d="M63.3,474.07c17.36,74.2,54.11,138.34,112.19,169.43,120.7,64.61,392.91,31.29,596.07-7A465.21,465.21,0,0,0,1036.09,485.3Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
      opacity="0.1"
    />
    <path
      d="M1128.65,124.81H1017.18a9.63,9.63,0,1,1,0-19.27h19.27a9.63,9.63,0,0,1,0-19.27h-13.76a9.63,9.63,0,0,1,0-19.27h58.18c-12.91-6-28-10.27-45.38-12.51-158.64-20.38-215.28-6.9-235.26,7-24.65,17.1-52.15,29.68-81.54,35.74C620.8,117.4,437.75,141.7,271.84,89.43c-82.46-26-143.75,17.92-180.48,91.28H1148c-1.38-21.32-5.51-41-13.09-58.24A9.58,9.58,0,0,1,1128.65,124.81Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
      opacity="0.1"
    />
    <path
      d="M53.84,319.2a491.06,491.06,0,0,0,4.33,129.46l978.68,35.79a460,460,0,0,0,68-103.7Zm387.08,92.35H421.65a9.63,9.63,0,0,1,0,19.27H310.18a9.63,9.63,0,1,1,0-19.27h19.27a9.63,9.63,0,0,1,0-19.27H315.69a9.63,9.63,0,0,1,0-19.27H427.16a9.63,9.63,0,1,1,0,19.27h13.76a9.63,9.63,0,0,1,0,19.27Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
      opacity="0.1"
    />
    <path
      d="M1148.05,181.38l-1068.49,26C66.57,240.79,58,278.56,54,317.71H1129.33a456.46,456.46,0,0,0,15.53-70.33C1148.06,224.37,1149.34,202.11,1148.05,181.38ZM270.92,266.55H251.65a9.63,9.63,0,0,1,0,19.27H140.18a9.63,9.63,0,1,1,0-19.27h19.27a9.63,9.63,0,0,1,0-19.27H145.69a9.63,9.63,0,0,1,0-19.27H257.16a9.63,9.63,0,1,1,0,19.27h13.76a9.63,9.63,0,0,1,0,19.27Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
      opacity="0.1"
    />
    <path
      d="M990.13,855.4S945.57,611,860.6,571.34c-72-33.63-149.86-56.3-172-62.45A95.61,95.61,0,0,0,677.19,494c-30.26-31.59-48.44-31-51.38-30.61a153.83,153.83,0,0,1-3-19c-.09-1-.18-1.93-.26-2.92l.26-.13c-.18-1.89-.34-3.84-.48-5.85a150.6,150.6,0,0,0,33.79-24.61c.13.43.24.86.38,1.3q32.71-27.65,64.07-56.8c6-5.61,12.3-11.7,14.34-19.61a28.78,28.78,0,0,0,.75-8.32c.27-6-1.15-12.13-1.39-18.21,0-1,.11-1.93.24-2.88,1.4-9.95,8.32-18.12,14-26.47a124.82,124.82,0,0,0,21.23-71.46,121,121,0,0,0-5-36c-4.43-14.82-13.65-30.39-29-33.63-5.94-1.25-12.11-.47-18.17-.86-25.09-1.6-43.87-22-63.88-36.95a168.61,168.61,0,0,0-83.58-32.44c-9.45-1-19.21-1.07-28.2,1.93-20.19,6.72-32.45,27.47-51.9,36-14.38,6.34-31,5.31-46,10.07-22.33,7.1-41.28,30-41.78,53.53,0,.87,0,1.74,0,2.61,0,.15,0,.3,0,.44-.25,11.89,4,24,4.34,36.17q0,1.65.05,3.31c-.11,13.14-1.68,26.32-6.47,38.67-2.58,6.65-5.9,14.35-5.36,21-.3,4.07.49,7.89,3.32,11l4.83-4.26a146,146,0,0,0-2.43,26.58c0,44.94,20.44,85.18,52.68,112.26q.28,4.49.54,9.62c.76,14.82,1.36,33.41,1.19,53.67l-47.16,43.35-121.24,55s-110.88,23.42-87,179.19l11.4,96.72H312.15l-.23.51H462.17l.09-.51H819.74l-.07.51h14v-.51ZM626.7,465.87a103.92,103.92,0,0,1,7.2,19.8,81.11,81.11,0,0,1-7.77-20.88l.15.27Zm-171.82,20q0,2.86-.1,5.77l-.24.34A49,49,0,0,1,454.88,485.82Z"
      transform="translate(-51.45 -44.1)"
      fill="url(#1c2fe1a5-71ac-4c34-8a58-b17380e220b9)"
    />
    <path
      d="M666.25,510.4l-20,191-107,34s-139-26-103-101c25.61-53.35,24.9-148.69,22.36-199.29-1-20.54-2.36-33.71-2.36-33.71s182-159,167-16c-2.9,27.69-3.07,49.34-1.46,66.26C628.54,522.1,666.25,510.4,666.25,510.4Z"
      transform="translate(-51.45 -44.1)"
      fill="#fdc2cc"
    />
    <path
      d="M666.25,510.4l-20,191-107,34s-139-26-103-101c25.61-53.35,24.9-148.69,22.36-199.29-1-20.54-2.36-33.71-2.36-33.71s182-159,167-16c-2.9,27.69-3.07,49.34-1.46,66.26C628.54,522.1,666.25,510.4,666.25,510.4Z"
      transform="translate(-51.45 -44.1)"
      opacity="0.03"
    />
    <path
      d="M623.25,382.4c-2.9,27.69-3.07,49.34-1.46,66.26a145.07,145.07,0,0,1-163.18-16.55c-1-20.54-2.36-33.71-2.36-33.71S638.25,239.4,623.25,382.4Z"
      transform="translate(-51.45 -44.1)"
      opacity="0.1"
    />
    <circle cx="500.8" cy="271.31" r="145" fill="#fdc2cc" />
    <circle cx="500.8" cy="271.31" r="145" opacity="0.03" />
    <path
      d="M976.25,855.4h-752l-11-95c-23-153,84-176,84-176l117-54,46.5-43.5c-15,57,98.5,175.5,98.5,175.5l0,0a26.88,26.88,0,0,0,2.07-2.15c3.06-3.34,10.37-11.7,27.49-32.44,6.12-7.43,13.51-16.44,22.41-27.38,42.8-52.6,20.84-114.75,14.28-127.6l-.41-.8c-.25-.46-.44-.83-.59-1.09a0,0,0,0,0,0,0,1.63,1.63,0,0,0-.15-.27,1.59,1.59,0,0,1-.11-.2l55,43s2.11.56,6,1.66c21.4,6,96.58,28.31,166,61.34C933.25,615.4,976.25,855.4,976.25,855.4Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
    />
    <path
      d="M720.25,626.4c-9-9-70-20-70-20,2,24-72,61-72,61l5.5,188H548.27l-6-169,17-24,0,0,2.07-2.15a330,330,0,0,0,27.49-32.44c6.12-7.43,13.51-16.44,22.41-27.38,42.8-52.6,20.84-114.75,14.28-127.6l-.41-.8c-.24-.47-.43-.83-.59-1.09a0,0,0,0,0,0,0,1.63,1.63,0,0,0-.15-.27,1.59,1.59,0,0,1-.11-.2h0s18-4,50,30a93.75,93.75,0,0,1,11,14.66C711.26,556.85,728.18,634.33,720.25,626.4Z"
      transform="translate(-51.45 -44.1)"
      fill="#19e06f"
    />
    <path
      d="M720.25,626.4c-9-9-70-20-70-20,2,24-72,61-72,61l5.5,188H548.27l-6-169,17-24,0,0,2.07-2.15a330,330,0,0,0,27.49-32.44c6.12-7.43,13.51-16.44,22.41-27.38,42.8-52.6,20.84-114.75,14.28-127.6l-.41-.8c-.24-.47-.43-.83-.59-1.09a0,0,0,0,0,0,0,1.63,1.63,0,0,0-.15-.27,1.59,1.59,0,0,1-.11-.2h0s18-4,50,30a93.75,93.75,0,0,1,11,14.66C711.26,556.85,728.18,634.33,720.25,626.4Z"
      transform="translate(-51.45 -44.1)"
      opacity="0.1"
    />
    <path
      d="M313.25,703.4s47,96,49.5,152.5"
      transform="translate(-51.45 -44.1)"
      opacity="0.1"
    />
    <polygon
      points="773.8 714.31 760.3 811.81 773.8 811.81 773.8 714.31"
      opacity="0.1"
    />
    <path
      d="M323.75,855.9l74.5-169.5s21-40,26-80,32-103,32-103,67-85,98-73-27,57-27,57,85,33,27,131-59,80-59,80l-28.5,157.5Z"
      transform="translate(-51.45 -44.1)"
      opacity="0.1"
    />
    <path
      d="M321.75,855.9l74.5-169.5s21-40,26-80c3.18-25.43,15.25-60.16,23.62-82.09a125.86,125.86,0,0,1,23.72-39c21.66-24.24,61-63.33,82.66-54.93,31,12-27,57-27,57s85,33,27,131-59,80-59,80l-28.5,157.5Z"
      transform="translate(-51.45 -44.1)"
      fill="#fdc2cc"
    />
    <circle cx="545.3" cy="189.81" r="3" fill="#fff" opacity="0.15" />
    <circle cx="531.3" cy="195.81" r="3" fill="#fff" opacity="0.15" />
    <path
      d="M449.24,132.68c14.47-4.68,30.53-3.67,44.41-9.89,18.77-8.42,30.6-28.8,50.08-35.4,8.68-2.94,18.1-2.83,27.21-1.89a161.07,161.07,0,0,1,80.66,31.87c19.31,14.69,37.43,34.72,61.64,36.29,5.85.38,11.8-.39,17.53.84,14.84,3.18,23.73,18.47,28,33A124.26,124.26,0,0,1,743.11,290.1c-5.48,8.2-12.16,16.23-13.51,26-1.32,9.58,2.76,19.51.38,28.89-2,7.77-8,13.75-13.84,19.26Q685.89,392.86,654.31,420c-10.94-34.76,5.11-72.19,2.61-108.54-1-14.16-6-29.62-18.49-36.3-11.69-6.24-26.63-3-38.46-9-13.2-6.65-18.62-22.4-28.6-33.3-17-18.6-46.51-21-70.28-12.6s-43.2,25.68-62,42.53l-34.17,30.65c-6.67-7.52-1.61-19.06,2-28.46,5.38-14.09,6.56-29.28,6.2-44.24-.29-12-4.43-23.85-4.19-35.53C409.41,162.17,427.69,139.65,449.24,132.68Z"
      transform="translate(-51.45 -44.1)"
      opacity="0.1"
    />
    <path
      d="M449.24,129.68c14.47-4.68,30.53-3.67,44.41-9.89,18.77-8.42,30.6-28.8,50.08-35.4,8.68-2.94,18.1-2.83,27.21-1.89a161.07,161.07,0,0,1,80.66,31.87c19.31,14.69,37.43,34.72,61.64,36.29,5.85.38,11.8-.39,17.53.84,14.84,3.18,23.73,18.47,28,33A124.26,124.26,0,0,1,743.11,287.1c-5.48,8.2-12.16,16.23-13.51,26-1.32,9.58,2.76,19.51.38,28.89-2,7.77-8,13.75-13.84,19.26Q685.89,389.86,654.31,417c-10.94-34.76,5.11-72.19,2.61-108.54-1-14.16-6-29.62-18.49-36.3-11.69-6.24-26.63-3-38.46-9-13.2-6.65-18.62-22.4-28.6-33.3-17-18.6-46.51-21-70.28-12.6s-43.2,25.68-62,42.53l-34.17,30.65c-6.67-7.52-1.61-19.06,2-28.46,5.38-14.09,6.56-29.28,6.2-44.24-.29-12-4.43-23.85-4.19-35.53C409.41,159.17,427.69,136.65,449.24,129.68Z"
      transform="translate(-51.45 -44.1)"
      fill="#865a61"
    />
  </svg>
</template>

<script>
export default {
  name: "ResetPasswordSvg",
};
</script>

<style scoped>
svg {
  width: 100%;
  height: auto;
}
</style>
